import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import pdfIconPurple from "../../assets/pdf_icon_purple.svg";
import he_TIE_Booklet_Eins_Basis_vs10 from "../../assets/h-e_TIE_Booklet_Eins_Basis_vs21.pdf";
import he_TIE_Booklet_Zwei_Module_vs9 from "../../assets/h-e_TIE_Booklet_Zwei_Module_vs16.pdf";
import he_TIE_Booklet_Drei_Oekosystem_vs6 from "../../assets/h-e_TIE_Booklet_Drei_Oekosystem_vs8.pdf";
// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  mainContent,
  introSection,
  //description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  //hubSpotForm,
} from "../subpage.module.scss";

const BookletsPage = () => {
  return (
    <Layout pageTitle="Broschüren" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <h1>health-engine – Booklets </h1>
                <p className="text-medium">
                  Eine flexible Softwarelösung für Spitäler.
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <div>
                  <a
                    href={he_TIE_Booklet_Eins_Basis_vs10}
                    className="text-purple"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3 style={{ color: "#9b00ff" }}>
                      <img
                        style={{ marginRight: 16, width: 30, height: "auto" }}
                        src={pdfIconPurple}
                        alt=""
                      />
                      health-engine Basis&nbsp;&gt;
                    </h3>
                  </a>
                </div>

                <div>
                  <a
                    href={he_TIE_Booklet_Zwei_Module_vs9}
                    className="text-purple"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3 style={{ color: "#9b00ff" }} /*className="text-large"*/>
                      <img
                        style={{ marginRight: 16, width: 30, height: "auto" }}
                        src={pdfIconPurple}
                        alt=""
                      />
                      health-engine Module&nbsp;&gt;
                    </h3>
                  </a>
                </div>

                <div>
                  <a
                    href={he_TIE_Booklet_Drei_Oekosystem_vs6}
                    className="text-purple"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h3 style={{ color: "#9b00ff" }} /*className="text-large"*/>
                      <img
                        style={{ marginRight: 16, width: 30, height: "auto" }}
                        src={pdfIconPurple}
                        alt=""
                      />
                      health-engine Ökosystem&nbsp;&gt;
                    </h3>
                  </a>
                </div>

                <p className={marginTopSubstitute}>
                  Die drei Säulen der health-engine:
                </p>
                <p></p>
                <p className="text-blue">Datenhaltung</p>
                <p>
                  Die Datenhaltung gewährleistet die sichere und effiziente
                  Speicherung, Verwaltung sowie den schnellen Zugriff auf
                  Patientendaten und medizinische Informationen, die für eine
                  nahtlose Kommunikation und Koordination zwischen
                  Krankenhäusern, dem ambulanten Sektor und Partner-Netzwerk
                  unerlässlich sind.
                </p>
                <p></p>
                <p className="text-blue">Interoperabilitätsplattform</p>
                <p>
                  Die Kommunikations- und Interoperabilitätsplattform verbindet
                  Krankenhäuser, den ambulanten Sektor und unser
                  Partner-Netzwerk.
                </p>
                <p></p>
                <p className="text-blue">Prozessautomatisierung</p>
                <p>
                  Mit der integrierten Prozess-Steuerung werden klinische
                  Arbeitsabläufe automatisiert und gesteuert.
                </p>
                <p></p>
              </div>

              <div className={col12}>
                <p className="text-blue">Einleitung</p>
                <p>
                  {" "}
                  Gemeinsam mit unseren Lösungspartnern haben wir ein Ökosystem
                  verknüpfter Lösungen aufgebaut. Die Integration ist dabei über
                  die, von uns entwickelte, health-engine als zentrales Element
                  gegeben. Aus diesem stetig wachsenden Angebot standardisierter
                  Module setzen wir Ihre individuelle Lösung zusammen.{" "}
                </p>
                <p>
                  Wir passen Ihre bestehende IT-Infrastruktur ohne grossen
                  Aufwand an sich ändernde Rahmenbedingungen an. «Seit 2002
                  steigern wir die Qualität im Gesundheitswesen mit digitalen
                  Gesamtlösungen.»{" "}
                </p>
                <p>
                  Somit können bei der Digitalisierung der Prozesse einfach
                  innovative neue Lösungen eingebunden werden, die bisher mit
                  grossem Aufwand einzeln integriert werden mussten. Die
                  Umsetzung kann in grossen oder kleinen Schritten passieren, so
                  dass rasch ein Nutzen für die Anwender entsteht.{" "}
                </p>
                <p>
                  Wir freuen uns, mit Ihnen in Kontakt zu treten und den
                  nutzenstiftenden Einsatz in ihrem Haus zu besprechen.
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default BookletsPage;
